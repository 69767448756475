@import "common/variables-pdfmdx";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
// endbower

@import "../../bower_components/owl.carousel/src/scss/owl.carousel.scss";

@import "components/icons";


@import "common/mixins";
@import "common/global";
@import "components/transisations";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
//@import "components/blueimpl";
@import "components/lity";
@import "components/owl";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/home";
@import "layouts/pdftools";


.content p a, .related-blogposts .date a {
	color: #000 !important;
}